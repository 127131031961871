import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BlogList from "../../components/blogList"
import Image from "../../components/image"

const EssentialPage = () => (
  <Layout>
    <Seo title="Web／モバイルApp開発" />
    <main id="ww-service" data-role="service">
      <section className="ww-section-billboard web-app-dev">
        <div className="billboard-position-1 billboard-copy-wrapper">
          <div className="billboard-copyText-position">
            <h2 className="main-copy">Web / モバイルApp開発</h2>
            <p className="body-copy">
              先端のIT技術を分かり易い形でお伝えし、腑に落ちる形で運用していただき、長く親しまれるものにするための“継続的なアイデアと実践”をご提供します。
            </p>
          </div>
        </div>

        <div className="billboard-service-image-bg">
          <figure className="service-image-bg web-app-development"></figure>
          <Image filename="web-app-dev-billboard.jpg" />
        </div>
      </section>

      <section className="ww-section-detail web-app-dev">
        <div className="detail-position">
          <ul className="detail-item-position">
            <li className="detail-item detail-position-1">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">開発・運用のオーガナイズ</h3>
              <h4 className="detail-item-copy">
                開発を計画する、まとめる、チームを組織する、実践する
              </h4>
            </li>

            <li className="detail-item detail-position-2">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">リサーチと設計</h3>
              <h4 className="detail-item-copy">
                業務を知る、アイデアを視覚化する、良い体験を体系づける
              </h4>
            </li>

            <li className="detail-item detail-position-3">
              <figure className="detail-item-border"></figure>
              <h3 className="detail-item-header">ビジュアルとコードの開発</h3>
              <h4 className="detail-item-copy">
                ビジュアルを組む、コーディングする
              </h4>
            </li>
          </ul>

          <div className="detail-description">
            <Image filename="web-app-dev-image-cut-w1024.jpg" />
            <div className="detail-description-copy">
              <p>
                Webサイトやモバイル・アプリケーション、ソーシャル・メディア連携など、先端のIT技術を分かり易い形でお伝えすることで、ITにありがちである難解な専門技術すらも常に腑に落ちる形で実践し、ビジネスチャンスに変える。そのための様々なご協力をいたします。
              </p>
              <p>
                そして、WebサイトやモバイルAppを開発・運用する上で最も大切なことがあります。世の時流やビジネスの変化に合わせて常に手をかけて育てていかなければ、出来上がった頃に発していた新鮮さは、すぐに色あせてしまいます。そこでワンダウォールでは、常に使い易く、長く親しまれるものにするための“継続的なアイデアと実践”をご提供します。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="ww-section-portfolio">
        <div className="detail-position">
          <article className="portfolio-item">
            <a href="https://jima.media" className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="web-app-dev-jforum.jpg" />
            </a>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">
                WordPress + AWS で本格的なメディア／企業サイトをリーズナブルに
              </h3>
              <div className="portfolio-item-copy">
                <p>
                  WordPressとAWS Lightsailを活用し、本格的なメディア／企業サイトの更新性と信頼性を低コストで実現。
                </p>
                <p>
                  要望を具現化する提案、ワイヤーフレーム作成、デザインからサイト実装、サーバー構築、運用・サポートまでをワンストップで担当しています。
                </p>
                <strong>事例サイト：</strong>
                <ul className="portfolio-item-technology">
                  <li><a href="https://j-forum.org" target="_blank" rel="noreferrer" className="line-2">報道実務家フォーラム</a></li>
                  <li><a href="https://jima.media" target="_blank" rel="noreferrer" className="line-2">インターネットメディア協会</a></li>
                </ul>

              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <a href="https://store.kinokuniya.co.jp/store/" className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="web-app-dev-kino.jpg" />
            </a>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">
              WordPressフルカスタマイズで大規模な店舗／イベント情報発信サイトを構築
              </h3>
              <div className="portfolio-item-copy">
                <p>
                  WordPressを大きくカスタマイズし、店舗なら住所や位置情報、イベントなら日付をキーにした動的なサイト構築を実現。
                </p>
                <p>
                  常にサイトを柔軟に改良し、運用するために、設計の段階から将来を見据えた配慮を行い、拡張性に優れたプログラミング技法を導入しています。
                </p>

                <strong>事例サイト：</strong>
                <ul className="portfolio-item-technology">
                  <li><a href="https://store.kinokuniya.co.jp/store/" target="_blank" rel="noreferrer" className="line-2">紀伊國屋書店 - 店舗総合サイト</a></li>
                  <li><a href="https://www.kashiwa-marunaka.jp" target="_blank" rel="noreferrer" className="line-2">紀伊國屋書店 - イベント総合サイト</a></li>
                </ul>
              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <a href="https://www.kashiwa-marunaka.jp" className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="web-app-dev-kfm.jpg" />
            </a>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">
                GatsbyJS＆Reactで最先端Webサイト＋WordPressで使いやすいサイト管理
              </h3>
              <div className="portfolio-item-copy">
                <p>
                堅牢で高速なWebサイトを構築できることで注目を集める静的サイトジェネレーター「GatsbyJS」を国内でいち早く導入。
          
                </p>
                <p>
                コンテンツ管理は定評あるWordPressを使いつつ、フロントエンドのサイト生成はReactの特徴をフルに発揮する最先端Webサイトを提供します。
                </p>

                <strong>事例サイト：</strong>
                <ul className="portfolio-item-technology">
                  <li><a href="https://www.kashiwa-marunaka.jp" target="_blank" rel="noreferrer" className="line-2">柏魚市場 株式会社</a></li>
                  <li><a href="https://www.moderntimes.tv" target="_blank" rel="noreferrer" className="line-2">Modern Times</a></li>
                </ul>
              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <a href="https://topo.st/" className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="web-app-dev-topocard.jpg" />
            </a>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">Topo Card</h3>
              <div className="portfolio-item-copy">
                <p>
                  ワンダウォールによる自社プロダクトとして企画・設計・開発を行ったiPhone用の位置情報アプリケーション。
                </p>
                <p>
                  トータルで8万行にも及ぶコードは今も丁寧に手入れが施され、最新のiOSやiPhoneといった先端技術への対応も怠っていません。
                </p>

                <p className="portfolio-item-technology">
                  <span className="line-1">主要な技術:</span>
                  <span className="line-2">iOS, AWS, Swift, Objective-C</span>
                </p>
              </div>
            </div>
          </article>

          <article className="portfolio-item">
            <a href="http://www.moto.co.jp/" className="portfolio-item-image-link">
              <div className="portfolio-item-image-position"></div>
              <Image filename="web-app-dev-motoapp.jpg" />
            </a>

            <div className="portfolio-item-description">
              <span className="portfolio-item-genre">WORKS</span>
              <h3 className="portfolio-item-title">DaisyHeads / MRS</h3>
              <div className="portfolio-item-copy">
                <p>
                  ワンダウォールが企画・設計・開発を行った、日本を代表するロック・アーティスト、佐野元春の公式アプリケーション・シリーズ。
                </p>
                <p>
                  「MRS」は、FMラジオ番組の選曲リストのリアルタイム表示、過去30年間で紹介された4,000曲以上の楽曲リストのDB化に成功しました。
                </p>
                <p>
                  「DaisyHeads」は佐野元春の公式App。プッシュ通知などWebサイトには無い機能を活用し、情報をファンの手元にいち早く届けます。
                </p>

                <p className="portfolio-item-technology">
                  <span className="line-1">主要な技術:</span>
                  <span className="line-2">iOS, Python, Swift, Objective-C</span>
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section className="ww-section-price" id="price">
        <h2 className="price-title">
          価格
          <span className="caption">*</span>
        </h2>
        <figure className="price-title-underline"></figure>

        <ul className="price-position">
          <li className="price-item price-position-1">
            <h3 className="price-item-sub-title">モバイルApp開発プロデュース</h3>
            <p className="price-item-copy">
              要件の整理、基礎となるUI/UXデザイン、チーム編成とコーディネイト、開発ディレクション
            </p>
            <p className="price-item-priceTag">
              月額55万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-2">
            <h3 className="price-item-sub-title">
              大規模Webサイトのディレクション
            </h3>
            <p className="price-item-copy">
              要件の整理、基礎となるデザイン、サイト規模に応じたチーム編成とコーディネイト、開発ディレクション
            </p>
            <p className="price-item-priceTag">
              月額45万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-3">
            <h3 className="price-item-sub-title">中〜小規模Webサイト制作</h3>
            <p className="price-item-copy">
              要件に応じたサーバー構築、ビジュアル・デザイン、多言語サイトの開発、HTML5ベースのコーディング
            </p>
            <p className="price-item-priceTag">
              月額25万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>

          <li className="price-item price-position-4">
            <h3 className="price-item-sub-title">Webサイトの定期運用</h3>
            <p className="price-item-copy">
              数ページの新規作成、ブログ形式の記事アップデート、SNS運用サポート、運用に関する各種打ち合わせ
            </p>
            <p className="price-item-priceTag">
              月額15万円～
              <span className="caption">（税抜）</span>
            </p>
          </li>
        </ul>
        <span className="price-item legal-line">
          *
          過去の実績に基づいた参考価格です。開発の規模やスケジュールなどによって変動します。詳しくはお問い合わせください。
        </span>
      </section>
      <BlogList type="web-app-dev" />
    </main>
  </Layout>
)

export default EssentialPage
